// src/pages/ConfirmRegistration.js
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { confirmRegistration } from '../services/authService';
import './ConfirmRegistration.css';

const ConfirmRegistration = () => {
  const location = useLocation();
  const history = useHistory();
  const [message, setMessage] = useState('Confirming your account...');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const confirmUser = async () => {
      // Capturamos el token desde la ruta en lugar de los query params
      const token = location.pathname.split('/confirm/')[1];

      if (!token) {
        setMessage('Invalid confirmation link.');
        setLoading(false);
        return;
      }

      try {
        await confirmRegistration(token);
        setMessage('Your account has been successfully confirmed!');
      } catch (error) {
        setMessage(error.message || 'Failed to confirm your account.');
      } finally {
        setLoading(false);
        // Optionally redirect after a delay
        // setTimeout(() => history.push('/login'), 3000);
      }
    };

    confirmUser();
  }, [location, history]);

  return (
    <div className="confirm-container">
      {loading ? (
        <p className="confirm-message">{message}</p>
      ) : (
        <>
          <p className="confirm-message">{message}</p>
          <button className="confirm-button" onClick={() => history.push('/login')}>
            Go to Login
          </button>
        </>
      )}
    </div>
  );
};

export default ConfirmRegistration;
