import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Signup.css';
import { signup } from '../services/authService';

const Signup = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Asegúrate de validar que las contraseñas coincidan
      if (password !== retypePassword) {
        alert('Passwords do not match');
        return;
      }
      const data = await signup(fullName, email, password);
      console.log('Signup exitoso', data);
    } catch (error) {
      console.error('Error en el signup', error);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-box">
        <div className="toggle-container">
          <span className="toggle-option active">Sign Up</span>
          <Link to="/login" className="toggle-option">Login</Link>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Retype Password"
              value={retypePassword}
              onChange={(e) => setRetypePassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn-primary">Submit</button>
        </form>
        <div className="signup-link">
          Already have an account? <Link to="/login">Login</Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
