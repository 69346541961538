// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ConfirmRegistration from './pages/ConfirmRegistration';


function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route path="/confirm/:token" component={ConfirmRegistration} />
          {/* Puedes agregar más rutas aquí en el futuro */}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
