import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, { email, password });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const signup = async (username, email, password) => { // Cambié `name` a `username`
  try {
    const response = await axios.post(`${API_URL}/auth/register`, { username, email, password }); // Usar `username` en lugar de `name`
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Función para confirmar el registro del usuario
export const confirmRegistration = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/auth/confirm/${token}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

